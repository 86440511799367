import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable()
export class DataControlService {

  public data = {
    firstTime: true,
    29: '0',
    30: '0',
    31: '0',
    32: '0',
    33: '0',
    34: '0',
    status: 'disconnected'
  };

  constructor(private http: HttpClient) {
    setInterval(() => {
      this.getData();
    }, 6000);
  }

  getData() {
    this.http.get('https://api.sistena.io/api/v2/devices/6156c0458acbe9bdb139a15a', {
      headers: {
        from: 'external',
        apitoken: '1916ca48-b8bf-453a-a70c-df2bb8c7dfd2'
      }
    }).subscribe((data: any) => {
      console.log(data);
        this.data = data.device.registers;
        this.data['29'] = String(Number(data.device.registers['29']) / 100);
        this.data['30'] = data.device.registers['30'];
        this.data['31'] = data.device.registers['31'];
        this.data['32'] = data.device.registers['32'];
        this.data['33'] = String(Math.trunc(Number(data.device.registers['33']) / 10));
        this.data['34'] = String(Math.trunc(Number(data.device.registers['34']) / 10));
        this.data.firstTime = false;
        this.data.status = data.device.status;
    });
  }

  refreshData() {
    this.getData();
  }

  setData(topic: string, value: any) {
  }
}
