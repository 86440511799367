import { Component } from '@angular/core';
import { DataControlService } from './services/data-control.service';

declare var mqtt: any;

@Component({
  selector: 'app-home',
  templateUrl: 'home.page.html',
  styleUrls: ['home.page.scss'],
})
export class HomePage {

  // public data = {
  //   velocidadVentilador: '0',
  //   estadoFiltro: '0',
  //   velocidadMin: '0',
  //   velocidadMax: '0',
  //   estado: false
  // }

  // client: any;
  // clientId: string = 'mqttIonic' + Math.random().toString(16).substr(2, 8);
  // mqttOptions: any = {
  //   username: 'Zehnder',
  //   password: '1234567890',
  //   clientId: this.clientId,
  //   rejectUnauthorized: false
  // }
  // urlBase: string = 'esp32/sistena/datos/zehnder/17B03B44BEA4';

  constructor(public _dataControl: DataControlService) {
  //   this.client = mqtt.connect("ws://r4b914b1.us-east-1.emqx.cloud:8083/mqtt", this.mqttOptions);
  //   this.client.on('message', (topic, message, packet) => {
  //     switch(topic) {
  //       case this.urlBase + '/velocidadVentilador':
  //         this.data.velocidadVentilador = message.toString();
  //         break;
  //       case this.urlBase + '/estadoFiltro':
  //         this.data.estadoFiltro = message.toString();
  //         break;
  //       case this.urlBase + '/estado':
  //         this.data.estado = message.toString();
  //         if(message.toString() === '0') this.data.estado = false; else this.data.estado = true;
  //         break;
  //       case this.urlBase + '/minVent':
  //         this.data.velocidadMin = message.toString();
  //         break;
  //       case this.urlBase + '/maxVent':
  //         this.data.velocidadMax = message.toString();
  //         break;
  //     }
  //   });
  //   this.client.on('connect', () => {
  //     console.log('Conectado a mqtt');
  //     this.client.subscribe(this.urlBase + '/velocidadVentilador', { qos: 0 });
  //     this.client.subscribe(this.urlBase + '/estadoFiltro', { qos: 0 });
  //     this.client.subscribe(this.urlBase + '/estado', { qos: 0 });
  //     this.client.subscribe(this.urlBase + '/minVent', { qos: 0 });
  //     this.client.subscribe(this.urlBase + '/maxVent', { qos: 0 });
  //   })
    setTimeout(() => {
      this._dataControl.refreshData()
    }, 500);
  }

  doRefresh(event: any) {
    this._dataControl.refreshData();
    setTimeout(() => {
      event.target.complete();
    }, 3000);
  }

}
